import Select, {components} from 'react-select';
import selectChevron from '../../../assets/icons/select-chevron.svg';
import selectChevronDisabled from '../../../assets/icons/select-chevron-disabled.svg';
import {forwardRef} from 'react';

// Overwrite react-select components
const Control = ({ children, ...props }) => (
    <components.Control {...props}>
        {children}
    </components.Control>
);

const Input = ({...props}) => (
    <components.Input {...props} aria-activedescendant={undefined} />
)

const IndicatorsContainer = () => null;
const DropdownIndicator = () => null;
const IndicatorSeparator = () => null;

export const PortalSelectField = forwardRef(function PortalSelectField(props, ref) {
    const {options, placeholder, onChange, defaultValue, variant = "formField", disabled, ...otherProps} = props;

    return (
        <>
            <Select
                ref={ref}
                options={options}
                placeholder={placeholder}
                isDisabled={disabled}
                isSearchable={false}
                isClearable={false}
                noOptionsMessage={() => "Geen opties gevonden"}
                onChange={onChange}
                defaultValue={defaultValue}
                unstyled={true}
                components={{
                    Control,
                    Input,
                    DropdownIndicator,
                    IndicatorsContainer,
                    IndicatorSeparator
                }}
                styles={{
                    container: (baseStyles) => ({
                        ...baseStyles,
                        maxWidth: variant === "dropdown" && "230px",
                        width: "100%",
                    }),
                    control: (baseStyles, {isDisabled}) => ({
                        width: "100%",
                        backgroundImage: isDisabled ? `url(${selectChevronDisabled})` : `url(${selectChevron})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "right 13px top 50%",
                        backgroundSize: variant === "dropdown" ? "24px" : "32px",
                        backgroundColor: isDisabled ? "var(--color-input-background)" : variant === "dropdown" ? "var(--color-portal-button)" : "var(--color-white)",
                        color: isDisabled ? "var(--color-text-alpha-50)" : variant === "dropdown" ? "var(--color-primary)" : "var(--color-text)",
                        fontSize: "var(--fs-text)",
                        padding: variant === "dropdown" ? "10px 42px 11px 17px" : "11px 42px 11px 17px",
                        textAlign: variant === "dropdown" ? "center" : "left",
                        minHeight: variant === "dropdown" ? "45px" : "46px",
                        fontWeight: variant === "dropdown" && "var(--fw-bold)",
                        boxShadow: isDisabled ? "unset" : variant === "dropdown" ? "unset" : "var(--box-shadow)",
                        borderRadius: variant === "dropdown" ? "9999px" : "20px",
                        cursor: "pointer",
                        '&:hover': {
                            backgroundColor: variant === "dropdown" && "var(--color-portal-button-hover)",
                        },
                    }),
                    placeholder: () => ({
                        color: variant === "formField" && "var(--color-dark)",
                        opacity: variant === "formField" && ".3",
                        fontStyle: variant === "formField" && "italic",
                    }),
                    input: () => "",
                    valueContainer: () => ({
                        display: "flex",
                        alignItems: "center",
                        justifyContent: variant === "dropdown" && "center",
                    }),
                    menu: (baseStyles) => ({
                        ...baseStyles,
                        background: "var(--color-white)",
                        boxShadow: "var(--box-shadow)",
                        marginTop: "10px",
                        borderRadius: "10px",
                        overflow: "hidden"
                    }),
                    option: (baseStyles, {isSelected}) => ({
                        ...baseStyles,
                        cursor: "pointer",
                        padding: "16px",
                        background: isSelected && "var(--color-primary)",
                        fontSize: "var(--fs-text)",
                        color: isSelected ? "var(--color-white)" : "var(--color-text)",
                        '&:hover': {
                            background: isSelected ? "var(--color-primary-hover)" : "var(--color-portal-select-field-hover)",
                            color: isSelected && "var(--color-white)",
                        }
                    }),
                    noOptionsMessage: () => ({
                        color: "var(--color-dark)",
                    }),
                }}
                {...otherProps}
            />
        </>
    );
})