import styled from "styled-components";
import {AppliedFilter} from './AppliedFilter';
import React from 'react';
import {useSearchParams} from 'react-router-dom';
import dayjs from 'dayjs';

const StyledAppliedFilterList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`;

export const AppliedFilterList = ({healthInsuranceData}) => {
    let [searchParams, setSearchParams] = useSearchParams();

    // Retrieve the filters from searchParams
    const nextAppointmentFrom = searchParams.get("nextAppointmentFrom");
    const nextAppointmentUntil = searchParams.get("nextAppointmentUntil");
    const healthInsurance = searchParams.get("healthInsurance");
    const hasUncompletedQuestionnaires = searchParams.get("hasUncompletedQuestionnaires");

    // Delete/overwrite the filters so page will get removed
    function handleDeleteFilter(key) {
        const newParams = {
            ...(nextAppointmentFrom ? {nextAppointmentFrom} : {}),
            ...(nextAppointmentUntil ? {nextAppointmentUntil} : {}),
            ...(healthInsurance ? {healthInsurance} : {}),
            ...(hasUncompletedQuestionnaires ? {hasUncompletedQuestionnaires} : {}),
        }
        delete newParams[key];
        setSearchParams(newParams, { replace: true, preventScrollReset: true });
    }

    return (
        <StyledAppliedFilterList>
            {nextAppointmentFrom && <AppliedFilter title={'Eerstkomende afspraak vanaf'} value={dayjs(nextAppointmentFrom).format("DD-MM-YYYY")} onRemove={() => handleDeleteFilter("nextAppointmentFrom")} />}
            {nextAppointmentUntil && <AppliedFilter title={'Eerstkomende afspraak vóór'} value={dayjs(nextAppointmentUntil).format("DD-MM-YYYY")} onRemove={() => handleDeleteFilter("nextAppointmentUntil")} />}
            {healthInsurance && <AppliedFilter title={'Zorgverzekeraar'} value={healthInsuranceData?.find(item => item?.id?.toLowerCase() === healthInsurance)?.name} onRemove={() => handleDeleteFilter("healthInsurance")} />}
            {hasUncompletedQuestionnaires && <AppliedFilter title={'Openstaande vragenlijsten'} value={hasUncompletedQuestionnaires === "1" ? "Ja" : hasUncompletedQuestionnaires === "0" ? "Nee" : ""} onRemove={() => handleDeleteFilter("hasUncompletedQuestionnaires")} />}
        </StyledAppliedFilterList>
    );
}