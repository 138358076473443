import styled from 'styled-components';
import {useState} from 'react';
import {ReactComponent as Info} from '../../assets/icons/info.svg';

const StyledInfoTooltip = styled.div``;

const TooltipWrapper = styled.div`
    display: inline-block;
    position: relative;
    margin-left: 8px;
`;

const TooltipContent = styled.div`
    position: absolute;
    border-radius: 8px;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(calc(-100% - 12px));
    padding: 10px;
    color: var(--color-white);
    background: var(--color-primary);
    z-index: 99;
    width: max-content;
    max-width: 400px;
    font-size: var(--fs-info-tooltip);
    line-height: 1.15;
    font-weight: var(--fw-regular);
    pointer-events: none;

    &::before {
        content: "";
        position: absolute;
        top: calc(100% - 1px);
        left: 50%;
        transform: translateX(-50%);
        height: 0;
        width: 0;
        border: 8px solid transparent;
        border-top-color: var(--color-primary);
        pointer-events: none;
    }
`;

const InfoIcon = styled(Info)`
    width: 20px;
    height: 20px;
    vertical-align: text-bottom;
`;

export const InfoTooltip = ({className, children, content, delay}) => {
    let timeout;
    const [isOpen, setIsOpen] = useState(false);

    const handleMouseEnter = () => {
        timeout = setTimeout(() => {
            setIsOpen(true);
        }, delay || 100);
    }

    const handleMouseLeave = () => {
        clearInterval(timeout);
        setIsOpen(false);
    }

    return (
        <StyledInfoTooltip
            className={className}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {children}
            
            <TooltipWrapper>
                <InfoIcon />
                {isOpen &&
                    <TooltipContent>
                        {content}
                    </TooltipContent>
                }
            </TooltipWrapper>
        </StyledInfoTooltip>
    );
}