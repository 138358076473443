import {CustomLink} from '../CustomLink/CustomLink';
import styled from 'styled-components';
import {Button} from '../Button/Button';
import {ReactComponent as Logo} from '../../assets/logo.svg';
import {Heading2} from '../Heading/Heading';
import {ReactComponent as Google} from '../../assets/icons/google.svg';
import {ReactComponent as Warning} from '../../assets/icons/warning.svg';
import {Text} from '../Text/Text';
import {useLocation} from 'react-router-dom';
import {LogoutReasons} from '../../constants/enums';

const StyledLogo = styled(Logo)`
    display: block;
    width: 200px;
    height: 147px;
    object-fit: cover;
    margin: 0 auto 35px;
`;

const StyledButton = styled(Button)`
    width: 100%;
    margin: 0 0 30px;
`;

const StyledGoogle = styled(Google)`
    width: 20px;
    height: 20px;
    margin-right: 5px;
    flex-shrink: 0;
`;

const Flex = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    gap: 20px;
`;

const Reason = styled.div`
    background-color: var(--color-primary-transparent);
    padding: 16px;
    display: flex;
    gap: 10px;
    border-radius: 5px;
`;

const StyledWarning = styled(Warning)`
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    margin-top: 3px;
`;

const StyledText = styled(Text)`
    color: var(--color-primary)
`;

export const LoginForm = () => {
    const location = useLocation();
    const redirectUrl = location.state?.from?.pathname;
    const loginUrl = Boolean(redirectUrl) ? `${process.env.REACT_APP_API_BASE_URL}/portal/login?redirectUrl=${redirectUrl}` : `${process.env.REACT_APP_API_BASE_URL}/portal/login`;
    const logoutReason = location.state?.reason === LogoutReasons.TOKEN_EXPIRED ? "Je bent uitgelogd omdat je sessie is verlopen. Log opnieuw in om verder te gaan waar je gebleven was." : location.state?.reason === LogoutReasons.MANUALLY ? "Je bent succesvol uitgelogd." : false;

    return (
        <>
            <StyledLogo />
            <Flex>
                <Heading2 as="h1" $align="center" $noMargin>Inloggen bij het behandelportaal</Heading2>
                {Boolean(logoutReason) &&
                    <Reason>
                        <StyledWarning />
                        <StyledText $noMargin>{logoutReason}</StyledText>
                    </Reason>
                }
            </Flex>
            <StyledButton type="login" to={loginUrl}><StyledGoogle /> Inloggen met Google</StyledButton>
            <Text $align="center" $noMargin>Lukt het niet? Neem <CustomLink to="mailto:icthelpdesk@adhdcentraal.nl">contact</CustomLink> met ons op</Text>
        </>
    );
}