import React, {useEffect, useRef, useState} from 'react';
import styled, {css} from 'styled-components';
import {Button} from '../../../components/Button/Button';
import {ReactComponent as Filter} from "../../../assets/icons/filter2.svg";
import {useClickInside} from '../../../hooks/useClickInside';
import {Heading2} from '../../../components/Heading/Heading';
import {FormFieldWrapper} from '../../../components/FormFields/FormFields';
import {useSearchParams} from 'react-router-dom';
import {PortalDateTimeField} from '../../../components/PortalFormFields/PortalDateTimeField/PortalDateTimeField';
import {PortalSelectField} from '../../../components/PortalFormFields/PortalSelectField/PortalSelectField';
import dayjs from 'dayjs';

const FilterButtonWrapper = styled.div`
    position: relative;
`;

const StyledFilterButton = styled(Button)`
    position: relative;
    background-color: var(--color-portal-button);
    color: var(--color-primary);
    min-width: 200px;
    
    &:hover { background-color: var(--color-portal-button-hover); }
    ${({$isOpen}) => $isOpen && css`background-color: var(--color-portal-button-hover); `};
`;

const StyledFilter = styled(Filter)`
    width: 20px;
    height: 20px;
    flex-shrink: 0;
`;

const FilterCount = styled.span`
    border-radius: 4px;
    padding: 0 6px;
    text-align: center;
    background-color: rgba(var(--color-blue-50-rgb), 0.1);
`;

const FlyOut = styled.div`
    width: 600px;
    position: absolute;
    top: 55px;
    right: 0;
    z-index: 1;
    border: 1px solid var(--color-grey-20);
    background-color: var(--color-background);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 30px;
    box-shadow: var(--box-shadow);
    text-align: left;
    cursor: default;
`;

const Fields = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
    column-gap: 30px;
`;

const StyledFormFieldWrapper = styled(FormFieldWrapper)`
    min-width: 0;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    
    span {
        min-width: 280px;
    }
`;

export function FilterButton({healthInsuranceData, onAfterSubmit}) {
    const [isOpen, setIsOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    // Retrieve the filters from searchParams
    const nextAppointmentFrom = searchParams.get("nextAppointmentFrom");
    const nextAppointmentUntil = searchParams.get("nextAppointmentUntil");
    const healthInsurance = searchParams.get("healthInsurance");
    const hasUncompletedQuestionnaires = searchParams.get("hasUncompletedQuestionnaires");
    const selectedFilters = [nextAppointmentFrom, nextAppointmentUntil, healthInsurance, hasUncompletedQuestionnaires];

    // Set the unappliedFilters initially to current searchParams
    const [unappliedFilters, setUnappliedFilters] = useState({
        nextAppointmentFrom,
        nextAppointmentUntil,
        healthInsurance,
        hasUncompletedQuestionnaires,
    });

    // Keep unappliedFilters correct when searchParams change (when deleting in AppliedFilterList)
    useEffect(() => {
        setUnappliedFilters({
            nextAppointmentFrom,
            nextAppointmentUntil,
            healthInsurance,
            hasUncompletedQuestionnaires,
        })
    }, [nextAppointmentFrom, nextAppointmentUntil, healthInsurance, hasUncompletedQuestionnaires]);

    function handleUnappliedFiltersChange(key, value) {
        setUnappliedFilters({
            ...unappliedFilters,
            [key]: value
        })
    }

    function handleApply() {
        // Set search params optionally
        const newParams = {
            ...(unappliedFilters.nextAppointmentFrom ? {nextAppointmentFrom: unappliedFilters.nextAppointmentFrom} : {}),
            ...(unappliedFilters.nextAppointmentUntil ? {nextAppointmentUntil: unappliedFilters.nextAppointmentUntil} : {}),
            ...(unappliedFilters.healthInsurance ? {healthInsurance: unappliedFilters.healthInsurance} : {}),
            ...(unappliedFilters.hasUncompletedQuestionnaires ? {hasUncompletedQuestionnaires: unappliedFilters.hasUncompletedQuestionnaires} : {}),
        }
        setSearchParams(newParams, { replace: true, preventScrollReset: true });
        onAfterSubmit();
        setIsOpen(false);
    }

    // Close the fly out on click outside
    const insideRef = useRef(null);
    const insideFilterButton = useClickInside(insideRef);
    useEffect(() => {
        if(!insideFilterButton) { setIsOpen(false); }
    }, [insideFilterButton]);

    // Select fields options
    const healthInsuranceFilterOptions = healthInsuranceData?.map(item => ({value: item.id, label: item.name})) ?? [];
    healthInsuranceFilterOptions.unshift({ value: "", label: "Alle" });

    const hasUncompletedQuestionnairesFilterOptions = [
        { value: "", label: "Alle" },
        { value: "1", label: "Ja" },
        { value: "0", label: "Nee" },
    ];

    return (
        <FilterButtonWrapper ref={insideRef}>
            <StyledFilterButton
                type="button"
                suffix={<></>}
                onClick={() => {
                    if(!isOpen) setIsOpen(true);
                    if(isOpen && insideFilterButton) setIsOpen(false);
                }}
                $isOpen={isOpen}
            >
                <StyledFilter />
                Filters
                {selectedFilters?.filter(Boolean)?.length > 0 &&
                    <FilterCount>
                        {selectedFilters?.filter(Boolean)?.length}
                    </FilterCount>
                }
            </StyledFilterButton>

            {isOpen &&
                <FlyOut>
                    <Heading2 $noMargin>Filter op patiënten</Heading2>

                    <Fields>
                        <StyledFormFieldWrapper
                            id="nextAppointmentFrom"
                            label="Eerstkomende afspraak vanaf"
                        >
                            <PortalDateTimeField
                                placeholder="Kies een datum"
                                hideTime={true}
                                defaultValue={nextAppointmentFrom ? dayjs(nextAppointmentFrom).format("DD-MM-YYYY") : ""}
                                onChange={(date) => handleUnappliedFiltersChange("nextAppointmentFrom", date ? dayjs(date).format("YYYY-MM-DD") : "")}
                            />
                        </StyledFormFieldWrapper>

                        <StyledFormFieldWrapper
                            id="nextAppointmentUntil"
                            label="Eerstkomende afspraak vóór"
                        >
                            <PortalDateTimeField
                                placeholder="Kies een datum"
                                hideTime={true}
                                defaultValue={nextAppointmentUntil ? dayjs(nextAppointmentUntil).format("DD-MM-YYYY") : ""}
                                onChange={(date) => handleUnappliedFiltersChange("nextAppointmentUntil", date ? dayjs(date).format("YYYY-MM-DD") : "")}
                            />
                        </StyledFormFieldWrapper>

                        <StyledFormFieldWrapper
                            id="healthInsurance"
                            label="Zorgverzekeraar"
                        >
                            <PortalSelectField
                                options={healthInsuranceFilterOptions}
                                onChange={(option) => handleUnappliedFiltersChange("healthInsurance", option?.value?.toLowerCase())}
                                defaultValue={healthInsuranceFilterOptions?.find(item => item?.value?.toLowerCase() === healthInsurance) ?? healthInsuranceFilterOptions[0]}
                            />
                        </StyledFormFieldWrapper>

                        <StyledFormFieldWrapper
                            id="hasUncompletedQuestionnaires"
                            label="Openstaande vragenlijsten?"
                        >
                            <PortalSelectField
                                options={hasUncompletedQuestionnairesFilterOptions}
                                onChange={(option) => handleUnappliedFiltersChange("hasUncompletedQuestionnaires", option?.value)}
                                defaultValue={hasUncompletedQuestionnairesFilterOptions?.find(item => item?.value === hasUncompletedQuestionnaires) ?? hasUncompletedQuestionnairesFilterOptions[0]}
                            />
                        </StyledFormFieldWrapper>
                    </Fields>

                    <ButtonWrapper>
                        <Button as="span" onClick={handleApply}>Toepasssen</Button>
                    </ButtonWrapper>
                </FlyOut>
            }
        </FilterButtonWrapper>
    );
}