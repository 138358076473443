import {CustomLink} from '../CustomLink/CustomLink';
import {CSSTransition, SwitchTransition} from 'react-transition-group';
import logoText from '../../assets/logo-text.png';
import {checkAccountPermissions, getCurrentEnvironment} from '../../utils/helpers';
import {Permissions} from '../../constants/enums';
import {LogoutButton} from '../LogoutButton/LogoutButton';
import {useEffect, useRef, useState} from 'react';
import {useClickInside} from '../../hooks/useClickInside';
import {useRouteLoaderData} from 'react-router-dom';
import styled from 'styled-components';
import {ReactComponent as Logo} from '../../assets/logo.svg';
import {ReactComponent as Person} from '../../assets/icons/person.svg';
import {ReactComponent as Calendar} from '../../assets/icons/calendar.svg';
import {ReactComponent as Mail} from '../../assets/icons/mail.svg';
import {ReactComponent as Search2} from '../../assets/icons/search2.svg';
import {ReactComponent as Chevron} from '../../assets/icons/chevron.svg';
import {NavItem} from './NavItem';
import {getHomePage} from '../../routes/Login/Login';
import {Text} from '../Text/Text';

const StyledSideBar = styled.aside`
    padding: ${({$hasEnvironmentBar}) => $hasEnvironmentBar ? "40px 20px 20px 20px" : "20px"};
    height: 100%;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow-aside);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 99;
    display: flex;
    flex-direction: column;
    transition: width .2s ease;
    ${({$expanded}) => $expanded ? "width: 275px;" : "width: 92px;"};
`;

const SideBarHeader = styled.header`
    position: relative;
`;

const EnvironmentLabel = styled(Text)`
    position: absolute;
    bottom: -16px;
    font-size: var(--fs-environment);
    font-weight: var(--fw-bold);
    margin: 0;
    text-align: center;
    width: 100%;
`;

const ExpandButton = styled.button`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -36px; // padding of sidebar + half of btn width
    background-color: var(--color-white);
    cursor: pointer;
    border-radius: 8px;
    padding: 6px;
    border: 1px solid var(--color-grey-20); 
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledChevron = styled(Chevron)`
    width: 18px;
    height: 18px;
    transition: transform .2s ease;
    
    path {
        fill: var(--color-primary);
    }
    
    ${({$expanded}) => $expanded && "transform: rotate(180deg)"}
`;

const StyledLogoCollapsed = styled(Logo)`
    display: block;
    width: 52px;
    height: 44px;
    object-fit: cover;
    margin: 0 auto;
`;

const StyledLogoExpanded = styled.img`
    display: block;
    width: 150px;
    height: 44px;
    object-fit: cover;
`;

const MainNavItems = styled.nav`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 24px;
    margin-top: 24px;
    border-top: 1px solid var(--color-divider);
`;

const SubNavItems = styled.nav`
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: auto;
`;

export function SideBar() {
    const {account} = useRouteLoaderData("root");
    const currentEnvironment = getCurrentEnvironment();

    // SideBar state
    const [expanded, setExpanded] = useState(false);

    const collapseSideBar = () => {
        setExpanded(false);
    }

    // When you click outside the sidebar, it should collapse
    const sideBarRef = useRef(null);
    const insideSideBar = useClickInside(sideBarRef);
    useEffect(() => {
        if(!insideSideBar) { collapseSideBar(); }
    }, [insideSideBar]);

    return (
        <StyledSideBar ref={sideBarRef} $expanded={expanded} $hasEnvironmentBar={currentEnvironment.showBar}>
            <SideBarHeader>
                <CustomLink to={getHomePage(account)} onClick={collapseSideBar}>
                    <SwitchTransition mode="out-in">
                        <CSSTransition
                            key={expanded ? "expanded" : "collapsed"}
                            timeout={{
                                enter: 100,
                                exit: 100
                            }}
                            classNames="fade"
                        >
                            <div>
                                {expanded ? <StyledLogoExpanded src={logoText} alt="" /> : <StyledLogoCollapsed />}
                            </div>
                        </CSSTransition>
                    </SwitchTransition>
                </CustomLink>

                <ExpandButton onClick={() => setExpanded(!expanded)}>
                    <StyledChevron $expanded={expanded} />
                </ExpandButton>

                {currentEnvironment.showLabel &&
                    <EnvironmentLabel>{currentEnvironment.shortLabel ?? currentEnvironment.label}</EnvironmentLabel>
                }
            </SideBarHeader>

            <MainNavItems>
                {Boolean(account?.profession) &&
                    <NavItem
                        expanded={expanded}
                        collapseSideBar={collapseSideBar}
                        label="Patiënten"
                        to="/patients/overview"
                    >
                        <Person />
                    </NavItem>
                }
                {checkAccountPermissions(account, [Permissions.PORTAL_ACCESS_QUESTIONNAIRE_OVERVIEW, Permissions.PATIENT_VIEW]) &&
                    <NavItem
                        expanded={expanded}
                        collapseSideBar={collapseSideBar}
                        label="Diagnostiekdag"
                        to="/questionnaire/overview"
                    >
                        <Calendar />
                    </NavItem>
                }
                {checkAccountPermissions(account, [Permissions.PORTAL_ACCESS_NOTIFICATION_CENTER_OVERVIEW, Permissions.NOTIFICATION_VIEW]) &&
                    <NavItem
                        expanded={expanded}
                        collapseSideBar={collapseSideBar}
                        label="Berichtencentrum"
                        to="/berichtencentrum"
                        subItems={[
                            {
                                label: "Berichtencentrum",
                                to: "/berichtencentrum"
                            },
                            {
                                label: "Verstuurde berichten",
                                to: "/berichtencentrum/verstuurde-berichten"
                            },
                        ]}
                        useFill
                    >
                        <Mail />
                    </NavItem>
                }
                {checkAccountPermissions(account, [Permissions.PORTAL_ACCESS_SEARCH, Permissions.PATIENT_VIEW]) &&
                    <NavItem
                        expanded={expanded}
                        collapseSideBar={collapseSideBar}
                        label="Zoeken"
                        to="/patient/search"
                        useFill
                    >
                        <Search2 />
                    </NavItem>
                }
            </MainNavItems>

            <SubNavItems>
                <LogoutButton expanded={expanded} />
            </SubNavItems>
        </StyledSideBar>
    );
}