import {Heading1} from "../../components/Heading/Heading";
import React, {useRef, useState} from "react";
import styled from "styled-components";
import {Input} from "../../components/Input/Input";
import SearchIcon from "../../assets/icons/search.svg"
import CrossIcon from "../../assets/icons/close.svg"
import {Text} from "../../components/Text/Text";
import {ChevronIcon, Table, TableHead, TableHeadCell, TableHeadRow, TableRow, TableRowCell, TableRowInner} from "../../components/Table/Table";
import {Permissions} from "../../constants/enums";
import {Spinner, SpinnerOverlay, SpinnerWrapper} from "../../components/Spinner/Spinner";
import Pagination from "../../components/Pagination";
import {Await, defer, Form, useLoaderData, useLocation, useNavigation, useSearchParams, useSubmit} from "react-router-dom";
import {checkRequiredAccount} from '../Root/Root';
import qs from 'qs';
import {fetchProtectedData} from '../../api/fetch';
import {useDebouncedCallback} from 'use-debounce';
import {LayeredCustomLink} from '../../components/CustomLink/CustomLink';

const SearchHeadingContainer = styled.div`
    width: 100%;
    margin-bottom: 56px;
`;

const Image = styled.img`
    position: absolute;
    top: 50%;
    left: 17px; 
    transform: translateY(-50%);
    width: 20px; 
    height: 20px;
`;

const Chevron = styled(ChevronIcon)`
    width: 32px;
    height: 32px;
    vertical-align: middle;
    cursor: pointer;
`;

const Cross = styled.img`
    position: absolute;
    top: 50%;
    right: 17px; 
    transform: translateY(-50%);
    width: 20px; 
    height: 20px;
`;

const RelativeWrapper = styled.div`
    position: relative;
`;

const StyledInput = styled(Input)`
    padding-left: 50px;
`;

export async function searchLoader({request}) {
    await checkRequiredAccount([Permissions.PORTAL_ACCESS_SEARCH, Permissions.PATIENT_VIEW]);

    const url = new URL(request.url);
    const searchParams = new URLSearchParams(url?.search)

    const page = searchParams.get("page") ?? 1;
    const searchQuery = searchParams.get("q");
    // const location = Cookies.get('locationFilter');

    if(searchQuery?.trim()?.length >= 2) {
        const queryString = qs.stringify({
            ...(page && {page}),
            ...(searchQuery && {search: searchQuery}),
            // ...(location && {location}),
        });

        const searchPromise = fetchProtectedData(request,`patient/search${queryString ? `?${queryString}` : ""}`);

        return defer({searchPromise, q: searchQuery});
    }

    return {q: ""};
}

const Search = () => {
    const data = useLoaderData();
    const location = useLocation();
    let [searchParams, setSearchParams] = useSearchParams();
    const navigation = useNavigation();
    const isSearching = navigation?.location?.pathname?.includes("/patient/search") && new URLSearchParams(navigation?.location?.search).has("q");
    const [query, setQuery] = useState(data?.q);
    const ref = useRef(null);
    let submit = useSubmit();

    const handleFormChange = (e) => {
        const newQuery = e?.target?.value ?? "";
        setQuery(newQuery);

        if(newQuery === "" || newQuery?.trim()?.length >= 2) debouncedSubmit();
    }

    const handleClearForm = () => {
        setQuery("")
        searchParams.set("q", "");
        setSearchParams(searchParams, { replace: true, preventScrollReset: true });
    }

    const debouncedSubmit = useDebouncedCallback(() => {
        submit(ref.current, { replace: true });
    }, 500);

    return (
        <>
            <SearchHeadingContainer>
                <Heading1>Zoeken op patiënt</Heading1>
                <Form
                    ref={ref}
                    id="search-form"
                    role="search"
                    action={location.pathname}
                    preventScrollReset={true}
                    replace={true}
                >
                    <div>
                        <RelativeWrapper>
                            <Image src={SearchIcon} alt="" />
                            <StyledInput
                                id="q"
                                aria-label="Zoek op voornaam, achternaam, geboortedatum (DD-MM-JJJJ) of Medicore ID (5 middelste cijfers)"
                                placeholder="Zoek op voornaam, achternaam, geboortedatum (DD-MM-JJJJ) of Medicore ID (5 middelste cijfers)"
                                name="q"
                                value={query || ""}
                                onChange={handleFormChange}
                            />
                            {query?.trim().length > 0 && (
                                <Cross src={CrossIcon} alt="" onClick={handleClearForm} />
                            )}
                        </RelativeWrapper>
                    </div>
                </Form>
            </SearchHeadingContainer>

            {data?.searchPromise ? (
                <React.Suspense fallback={
                    <SpinnerWrapper>
                        <Spinner />
                    </SpinnerWrapper>
                }>
                    <Await resolve={data?.searchPromise} errorElement={<Text $error>Er is iets misgegaan, probeer het opnieuw.</Text>}>
                        {(searchData) => {
                            return (
                                <RelativeWrapper>
                                    {searchData?.items?.length > 0 ? (
                                        <>
                                            <Pagination searchResults={searchData} $position="top" />
                                            <Table>
                                                <TableHead>
                                                    <TableHeadRow>
                                                        <TableHeadCell $fontSize={"var(--fs-heading-3)"}>Naam patiënt</TableHeadCell>
                                                        <TableHeadCell $fontSize={"var(--fs-heading-3)"}>Medicore ID</TableHeadCell>
                                                        <TableHeadCell $fontSize={'var(--fs-heading-3)'}>Geboortedatum</TableHeadCell>
                                                        <TableHeadCell $fontSize={'var(--fs-heading-3)'}>E-mailadres</TableHeadCell>
                                                        <TableHeadCell $fontSize={'var(--fs-heading-3)'}>Telefoonnummer</TableHeadCell>
                                                        <TableHeadCell $fontSize={'var(--fs-heading-3)'}>Locatie</TableHeadCell>
                                                        <TableHeadCell $flex="0" $minWidth="72px"></TableHeadCell>
                                                    </TableHeadRow>
                                                </TableHead>

                                                {searchData?.items?.map((item, rowIndex) => {
                                                    const key = `${rowIndex}-${item?.cells?.[0]}`;
                                                    const isOddRow = rowIndex % 2 === 0;

                                                    return (
                                                        <TableRow key={key} $isClickable={true}>
                                                            <TableRowInner $isOddRow={isOddRow}>
                                                                <TableRowCell>{item.name && item.name}</TableRowCell>
                                                                <TableRowCell>{item.medicoreID && item.medicoreID}</TableRowCell>
                                                                <TableRowCell>{item.birthDate && item.birthDate}</TableRowCell>
                                                                <TableRowCell>{item.email && item.email}</TableRowCell>
                                                                <TableRowCell>{item.phoneNumber && item.phoneNumber}</TableRowCell>
                                                                <TableRowCell>{item?.location?.[0] && item.location[0]}</TableRowCell>
                                                                <TableRowCell $flex="0" $minWidth="72px">
                                                                    <LayeredCustomLink to={`/patient/${item.id}`}><Chevron /></LayeredCustomLink>
                                                                </TableRowCell>
                                                            </TableRowInner>
                                                        </TableRow>
                                                    );
                                                })}
                                            </Table>
                                            <Pagination searchResults={searchData} />
                                        </>
                                    ) : (
                                        <Text>Geen resultaten gevonden</Text>
                                    )}

                                    {isSearching &&
                                        <SpinnerOverlay>
                                            <SpinnerWrapper>
                                                <Spinner />
                                            </SpinnerWrapper>
                                        </SpinnerOverlay>
                                    }
                                </RelativeWrapper>
                            );
                        }}
                    </Await>
                </React.Suspense>
            ) : isSearching ? (
                <SpinnerWrapper>
                    <Spinner />
                </SpinnerWrapper>
            ) : null}
        </>
    )
}

export default Search

