import styled from 'styled-components';
import {LocationButton} from '../LocationButton/LocationButton';
import {useFilterContext} from '../../contexts/FilterContext';
import {useMatch} from 'react-router-dom';
import React from 'react';
import {WhatsNewButton} from './WhatsNewButton';
import {getCurrentEnvironment} from '../../utils/helpers';

const StyledTopBar = styled.div`
    position: sticky;
    top: ${({$hasEnvironmentBar}) => $hasEnvironmentBar ? "20px" : "0"};
    left: 0;
    right: 0;
    z-index: 98;
    padding: 0 60px;
    background-color: var(--color-background);
`;

const Inner = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 2px solid var(--color-divider);
    padding: 15px 0;
`;

export function TopBar({locationData}) {
    const { locationFilter } = useFilterContext();
    const selectedLocation = locationData?.find(item => item.id === locationFilter);
    const matchSearch = useMatch("/patient/search");
    const currentEnvironment = getCurrentEnvironment();

    // TODO: Tijdelijk hele TopBar verbergen, bij implementatie What's New onderstaande line verwijderen.
    if(Boolean(matchSearch)) return null;

    return (
        <StyledTopBar $hasEnvironmentBar={currentEnvironment.showBar}>
            <Inner>
                {!Boolean(matchSearch) && (
                    <LocationButton
                        expanded={true}
                        data={locationData}
                        label={selectedLocation?.name ? selectedLocation.name : "Alle locaties"}
                        code={selectedLocation?.code ? selectedLocation.code : "Alle"}
                        selectedLocation={selectedLocation}
                    />
                )}

                {/* TODO: What's New */}
                {false &&
                    <WhatsNewButton />
                }
            </Inner>
        </StyledTopBar>
    );
}