import React, {useState} from 'react';
import styled, {css} from 'styled-components';

const TooltipContainer = styled.div`
    position: relative;
    display: inline-block;
    cursor: pointer;
`;

const TooltipContent = styled.div`
    pointer-events: none;
    visibility: ${({show}) => (show ? 'visible' : 'hidden')};
    background-color: ${({backgroundColor}) => backgroundColor};
    color: white;
    text-align: center;
    border-radius: 8px;
    padding: 10px;
    position: absolute;
    z-index: 99;
    white-space: nowrap;
    font-size: var(--fs-search-button);
    box-shadow: 2px 2px 5px rgba(0,0,0, .05);
    
    ${({offsetX, offsetY}) => (css`
        transform: translate(${offsetX ?? 0}, ${offsetY ?? 0});
    `)};
    
    ${({placement}) => (placement === 'bottom' && css`
        top: calc(100% + 12px);
    `)};

    ${({placement}) => (placement === 'top' && css`
        bottom: calc(100% + 12px);
    `)};
    
    ${({orientation}) => (orientation === 'right' && css`
        left: 0;
    `)}

    ${({orientation}) => (orientation === 'left' && css`
        right: 0;
    `)}
`;

const TooltipContentInner = styled.div`
    position: relative;
`

const Triangle = styled.div`
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid ${({backgroundColor}) => backgroundColor};

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;


    ${({placement}) => (placement === 'bottom' && css`
        transform: rotate(45deg) translateY(-50%);
        top: -12px;
    `)};

    ${({placement}) => (placement === 'top' && css`
        transform: rotate(225deg) translateY(-50%);
        bottom: -12px;
    `)};

    ${({placement, orientation}) => ((placement === 'top' && orientation === 'right') && css`
        left: 12px;
    `)}

    ${({placement, orientation}) => ((placement === 'bottom' && orientation === 'right') && css`
        left: -8px;
    `)}

    ${({placement, orientation}) => ((placement === 'top' && orientation === 'left') && css`
        right: -8px;
    `)}

    ${({placement, orientation}) => ((placement === 'bottom' && orientation === 'left') && css`
        right: 12px;
    `)}
`;

const Tooltip = ({
    className,
    content,
    children,
    placement = 'bottom',
    orientation = 'right',
    offsetX,
    offsetY,
    backgroundColor = 'var(--color-blue-50)'
}) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    return (
        <TooltipContainer onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {children}
            <TooltipContent className={className} show={showTooltip} placement={placement} orientation={orientation} offsetX={offsetX} offsetY={offsetY} backgroundColor={backgroundColor}>
                <TooltipContentInner>
                    {content}
                    <Triangle placement={placement} orientation={orientation} backgroundColor={backgroundColor}/>
                </TooltipContentInner>
            </TooltipContent>
        </TooltipContainer>
    );
};

export default Tooltip;