import {LoginForm} from '../../components/LoginForm/LoginForm';
import {checkOptionalAccount} from '../Root/Root';
import {redirect} from 'react-router-dom';
import {checkAccountPermissions} from '../../utils/helpers';
import {Permissions} from '../../constants/enums';

export function getHomePage(account) {
    if(Boolean(account?.profession)) {
        return "/patients/overview"
    }

    if(checkAccountPermissions(account, [Permissions.PORTAL_ACCESS_QUESTIONNAIRE_OVERVIEW, Permissions.PATIENT_VIEW])) {
        return "/questionnaire/overview"
    }

    if(checkAccountPermissions(account, [Permissions.PORTAL_ACCESS_NOTIFICATION_CENTER_OVERVIEW, Permissions.NOTIFICATION_VIEW])) {
        return "/berichtencentrum"
    }

    if(checkAccountPermissions(account, [Permissions.PORTAL_ACCESS_SEARCH, Permissions.PATIENT_VIEW])) {
        return "/patient/search"
    }

    return "/patient-niet-gevonden"
}

export async function loginLoader() {
    const {account} = await checkOptionalAccount();

    // If account (already logged in), redirect to correct start page instead
    if(account) {
        const redirectUrl = getHomePage(account);
        return redirect(redirectUrl);
    }

    return true;
}

const Login = () => {
    return (
        <LoginForm />
    );
}

export default Login;