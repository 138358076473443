import styled from 'styled-components';
import checkboxInactive from '../../assets/icons/checkbox-inactive.svg';
import checkboxChecked from '../../assets/icons/checkbox-checked.svg';
import checkboxCheckedDisabled from '../../assets/icons/checkbox-checked-disabled.svg';

export const Input = styled.input`
    width: 100%;
    min-height: 46px;
    padding: 13px 17px;
    padding-left: ${(props) => props.paddingLeft ? props.paddingLeft : 'auto'};
    border-radius: 20px;
    color: var(--color-text);
    font-size: var(--fs-text);
    background-color: var(--color-white);
    appearance: none;
    outline: none;
    border: none;
    box-shadow: var(--box-shadow);
    
    &:disabled {
        color: var(--color-text-alpha-50);
        background-color: var(--color-input-background);
        box-shadow: unset;
    }

    ::placeholder {
        color: var(--color-dark);
        opacity: 0.3;
        font-style: italic;
    }
`;

export const Checkbox = styled.input`
    appearance: none;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    content: url(${checkboxInactive});

    &:not(:disabled):checked {
        content: url(${checkboxChecked});
    }

    &:disabled:checked {
        content: url(${checkboxCheckedDisabled});
    }
`;