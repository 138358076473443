import DatePicker from 'react-multi-date-picker';
import {forwardRef, useCallback, useRef, useState} from 'react';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import datetime_locale from '../../../utils/datetime_locale';
import styled from 'styled-components';
import dateIcon from '../../../assets/icons/date.svg';
import dateDisabledIcon from '../../../assets/icons/date-disabled.svg';
import {ReactComponent as CloseIcon} from '../../../assets/icons/close.svg';
import dayjs from 'dayjs';
import {useClickOutside} from '../../../hooks/useClickOutside';

const StyledPortalDateTimeField = styled.div`
    position: relative;
    height: fit-content;
    
    .custom-date-time-field {
        width: 100%;
        min-height: 46px;
        padding: 13px 42px 13px 47px;
        border-radius: 20px;
        color: var(--color-text);
        font-size: var(--fs-text);
        background-color: var(--color-white);
        appearance: none;
        outline: none;
        border: none;
        box-shadow: var(--box-shadow);
        background-image: ${({$disabled}) => $disabled ? `url(${dateDisabledIcon})` : `url(${dateIcon})`};
        background-repeat: no-repeat;
        background-position: left 13px top 50%;

        &:disabled {
            color: var(--color-text-alpha-50);
            background-color: var(--color-input-background);
            box-shadow: unset;
        }

        ::placeholder {
            color: var(--color-dark);
            opacity: 0.3;
            font-style: italic;
        }
    }

    .rmdp-container { width: 100%; }
    .rmdp-shadow { box-shadow: var(--box-shadow); }
    .rmdp-header-values { font-weight: var(--fw-bold); }
    
    .rmdp-week-day { 
        color: var(--color-primary); 
        font-weight: var(--fw-bold);
    }
    
    .rmdp-arrow-container {
        .rmdp-arrow {
            border: solid var(--color-primary);
            border-width: 0 2px 2px 0;
            margin-top: 7px;
        }
        
        &:hover {
            background-color: var(--color-primary);

            .rmdp-arrow {
                border: solid var(--color-white);
                border-width: 0 2px 2px 0;
            }
        }
    }
    
    .rmdp-today span { 
        background-color: var(--color-table-odd-row); 
        color: var(--color-primary);
    }
    
    .rmdp-selected span:not(.highlight) { 
        background-color: var(--color-primary);
        box-shadow: unset;
    }

    @media (hover: hover) {
        .rmdp-day:not(.rmdp-disabled,.rmdp-day-hidden) span:hover {
            background-color: var(--color-table-odd-row);
            color: var(--color-primary);
        }
    }
`;

const StyledCloseIcon = styled(CloseIcon)`
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    right: 19px;
    transform: translateY(-50%);
    cursor: pointer;
`;

export const PortalDateTimeField = forwardRef(function PortalDateTimeField(props, ref) {
    const {defaultValue = "", placeholder, hideTime = false, disabled, onChange} = props;

    const [value, setValue] = useState(defaultValue);
    const format = hideTime ? "DD-MM-YYYY" : "DD-MM-YYYY HH:mm"
    const minDate = dayjs(new Date()).format(format);

    const handleChange = (value) => {
        setValue(value);
        onChange && onChange(value);
    }

    const handleClear = () => {
        setValue("");
        onChange && onChange("");
    }

    // Close the popup using the ref, otherwise it's not closing in a modal
    const localRef = useRef(null);
    const handleClose = useCallback(
        () => ref?.current ? ref.current.closeCalendar() : localRef?.current?.closeCalendar(),
        [ref, localRef]
    )
    useClickOutside(ref ? ref : localRef, handleClose)

    return (
        <StyledPortalDateTimeField $disabled={disabled}>
            <DatePicker
                ref={ref ? ref : localRef}
                value={value}
                inputClass="custom-date-time-field"
                format={format}
                onChange={handleChange}
                weekStartDayIndex={1}
                showOtherDays={true}
                placeholder={placeholder}
                minDate={minDate}
                locale={datetime_locale}
                arrow={false}
                onOpenPickNewDate={false}
                disabled={disabled}
                plugins={hideTime ? [] : [
                    <TimePicker
                        position="bottom"
                        hideSeconds={true}
                    />
                ]}
            />

            {(value && !disabled) && <StyledCloseIcon onClick={handleClear} />}
        </StyledPortalDateTimeField>
    )
})