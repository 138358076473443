import styled from 'styled-components';
import {ResultTableStale} from './ResultTableStale';
import {ResultTable} from './ResultTable';
import {ResultParagraph} from './ResultParagraph';

const StyledQuestionnaireResults = styled.div`
    margin-top: 60px;
`;

export const QuestionnaireResults = ({questionnaireData}) => {
    const isStaleResult = questionnaireData?.result && !Array.isArray(questionnaireData?.result?.content) && questionnaireData?.result?.content?.body?.length > 0;
    const isResultWithTypes = questionnaireData?.result && Array.isArray(questionnaireData?.result?.content) && questionnaireData?.result?.content?.length > 0;

    if (!isStaleResult && !isResultWithTypes) return null;

    return (
        <StyledQuestionnaireResults>
            {isStaleResult && <ResultTableStale questionnaireData={questionnaireData} />}

            {isResultWithTypes &&
                questionnaireData?.result?.content?.map((item, index) => (
                    <ResultContentType
                        key={`${item?.type}-${index}`}
                        data={item}
                        resultType={questionnaireData?.result?.type}
                    />
                ))
            }
        </StyledQuestionnaireResults>
    );
}

const resultContentTypes = {
    "Paragraph": ResultParagraph,
    "Table": ResultTable,
}

const ResultContentType = ({data, resultType}) => {
    const Component = resultContentTypes[data?.type]

    if (!Component) return null;

    return (
        <Component data={data} resultType={resultType} />
    );
}