import {checkRequiredAccount} from '../Root/Root';
import {Permissions} from '../../constants/enums';
import {deleteData, fetchProtectedData, patchData} from '../../api/fetch';
import {Await, defer, useLoaderData, useRouteLoaderData} from 'react-router-dom';
import React, {useState} from 'react';
import {Spinner} from '../../components/Spinner/Spinner';
import {Text} from '../../components/Text/Text';
import {Table, TableHead, TableHeadCell, TableHeadRow, TableRow, TableRowButton, TableRowCell, TableRowInner, TrashIcon} from '../../components/Table/Table';
import styled from 'styled-components';
import {useOpenModal} from '../../hooks/useOpenModal';
import {checkAccountPermissions} from '../../utils/helpers';
import {DeletePlannedNotification} from './Partials/DeletePlannedNotification';
import {EditPlannedNotification} from './Partials/EditPlannedNotification';

const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
`;

export async function plannedNotificationsAction({request, params}) {
    let formData = await request.formData();
    let intent = formData.get("intent");

    switch(intent) {
        case "edit-planned-notification": {
            const notificationId = formData.get("id");

            const body = {
                title: formData.get("title"),
                pushAt: formData.get("pushAt"),
                message: formData.get("message"),
            }

            return await patchData(request, `patient/${params.patientUUID}/notification/${notificationId}`, JSON.stringify(body));
        }

        case "delete-planned-notification": {
            const plannedNotificationId = formData.get("plannedNotificationId");

            return await deleteData(request, `patient/${params.patientUUID}/notification/${plannedNotificationId}`);
        }

        default :
            return {"default": true};
    }
}

export async function plannedNotificationsLoader({request, params}) {
    await checkRequiredAccount(Permissions.NOTIFICATION_VIEW);

    const notificationPromise = fetchProtectedData(request,`patient/${params.patientUUID}/notification?isSent=false`);

    return defer({notificationPromise});
}

const PlannedNotifications = () => {
    const {account} = useRouteLoaderData("root");
    const {notificationPromise} = useLoaderData();
    const {isOpen, handleOpen, handleClose} = useOpenModal();
    const [targetId, setTargetId] = useState(null);
    const handleOpenExtended = (id) => {
        setTargetId(id);
        handleOpen();
    }

    return (
        <React.Suspense fallback={
            <SpinnerWrapper>
                <Spinner />
            </SpinnerWrapper>
        }>
            <Await resolve={notificationPromise} errorElement={<Text>De berichten kunnen niet worden opgehaald. Probeer het opnieuw.</Text>}>
                {(notificationData) => {
                    if(notificationData.length === 0) return <Text>Er zijn nog geen berichten toegevoegd.</Text>

                    return (
                        <>
                            <Table>
                                <TableHead>
                                    <TableHeadRow>
                                        <TableHeadCell $flex="0" $minWidth="196px">Datum</TableHeadCell>
                                        <TableHeadCell $flex="0" $minWidth="156px">Status</TableHeadCell>
                                        <TableHeadCell $flex="0" $minWidth="128px">Type</TableHeadCell>
                                        <TableHeadCell>Titel</TableHeadCell>
                                        <TableHeadCell>Bericht</TableHeadCell>
                                        <TableHeadCell $flex="0" $minWidth="48px"></TableHeadCell>
                                        <TableHeadCell $flex="0" $minWidth="48px"></TableHeadCell>
                                    </TableHeadRow>
                                </TableHead>

                                {notificationData.map((item, index) => {
                                    const isDeleted = Boolean(item.removedManually);

                                    return (
                                        <TableRow key={item.id} $isClickable={false}>
                                            <TableRowInner $isOddRow={index % 2 === 0} $deleted={isDeleted}>
                                                <TableRowCell $flex="0" $minWidth="196px" $deleted={isDeleted}>{item.sendAt}</TableRowCell>
                                                <TableRowCell $flex="0" $minWidth="156px" $deleted={isDeleted}>Ingepland</TableRowCell>
                                                <TableRowCell $flex="0" $minWidth="128px" $deleted={isDeleted}>{item.type}</TableRowCell>
                                                <TableRowCell $variant="title" $deleted={isDeleted}>{item.title}</TableRowCell>
                                                <TableRowCell $deleted={isDeleted}>{item.text}</TableRowCell>
                                                <TableRowCell $flex="0" $padding="16px 0" $minWidth="48px">
                                                    {(!isDeleted && checkAccountPermissions(account, Permissions.NOTIFICATION_EDIT)) &&
                                                        <EditPlannedNotification item={item} />
                                                    }
                                                </TableRowCell>
                                                <TableRowCell $flex="0" $padding="16px 0" $minWidth="48px">
                                                    {(!isDeleted && checkAccountPermissions(account, Permissions.NOTIFICATION_DELETE)) &&
                                                        <TableRowButton onClick={() => handleOpenExtended(item.id)}><TrashIcon /></TableRowButton>
                                                    }
                                                </TableRowCell>
                                            </TableRowInner>
                                        </TableRow>
                                    );
                                })}
                            </Table>

                            <DeletePlannedNotification isOpen={isOpen} handleClose={handleClose} targetId={targetId} />
                        </>
                    );
                }}
            </Await>
        </React.Suspense>
    );
}

export default PlannedNotifications;