import styled, {css} from 'styled-components';
import {SubHeading} from '../Heading/Heading';
import {ReactComponent as Location} from '../../assets/icons/location.svg';
import {ConditionalWrap} from '../Utils/Utils';
import Tooltip from '../Tooltip/Tooltip';
import {useEffect, useRef, useState} from 'react';
import {useFilterContext} from '../../contexts/FilterContext';
import {useClickInside} from '../../hooks/useClickInside';
import {NavItemLabel} from '../SideBar/NavItem';
import {useMatch, useSearchParams} from 'react-router-dom';

const LocationButtonWrapper = styled.div`
    position: relative;
`;

const StyledLocationButton = styled(SubHeading).attrs({
    as: "button"
})`
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 18px;
    border-radius: 8px;
    padding: 9px 14px;
    background: transparent;
    border: none;
    width: 100%;
    cursor: pointer;

    &:hover { background-color: var(--color-aside-item-hover); }
    ${({$isOpen}) => $isOpen && css`background-color: var(--color-aside-item-hover); `};
`;

const IconWrapper = styled.span`
    position: relative;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const LocationCode = styled.span`
    position: absolute;
    bottom: -6px;
    width: max-content;
    color: var(--color-primary);
    font-size: var(--fs-location-code);
    font-weight: var(--fw-extra-bold);
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
`;

const LocationIcon = styled(Location)`
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    ${({$expanded}) => !$expanded && 'transform: translateY(-6px);'};
    
    path {
        stroke: var(--color-primary);
    }
`;

const FlyOut = styled.div`
    min-width: 224px;
    width: max-content;
    position: absolute;
    top: 58px;
    left: 0;
    border: 1px solid var(--color-grey-20);
    background-color: var(--color-white);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px;
    box-shadow: var(--box-shadow);
    max-height: 85vh;
    overflow-y: auto;
`;

const LocationOption = styled(SubHeading).attrs({
    as: "span",
    $noMargin: true,
})`
    background-color: var(--color-white);
    border: none;
    width: 100%;
    color: var(--color-primary);
    border-radius: 6px;
    padding: 12px;
    text-decoration: none;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;

    &:hover { background-color: var(--color-aside-item-hover); }

    &.active {
        background-color: var(--color-primary);
        color: var(--color-white);
    }
`;

const StyledNavItemLabel = styled(NavItemLabel).attrs({
    $truncate: true
})`
    display: block;
`;

export const LocationButton = ({expanded, data, label, code, selectedLocation}) => {
    const [isOpen, setIsOpen] = useState(false);
    let [searchParams, setSearchParams] = useSearchParams();

    // Only refresh route on the following routes
    const matchQuestionnaireOverview = useMatch("/questionnaire/overview");
    const matchNotificationCenter = useMatch("/berichtencentrum");
    const matchNotificationCenterMessages = useMatch("/berichtencentrum/verstuurde-berichten");
    const matchPlannedNotificationCenterMessages = useMatch("/berichtencentrum/verstuurde-berichten/ingepland");
    const shouldTriggerRevalidate = Boolean(matchQuestionnaireOverview) || Boolean(matchNotificationCenter) || Boolean(matchNotificationCenterMessages) || Boolean(matchPlannedNotificationCenterMessages);

    // Set location filter in context
    const { setLocationFilter } = useFilterContext();
    const handleClick = (e, newFilter) => {
        e.stopPropagation()
        setIsOpen(false);
        setLocationFilter(newFilter);

        // Set page to 1 and trigger revalidation
        if(shouldTriggerRevalidate) {
            searchParams.set("page", 1);
            setSearchParams(searchParams, { preventScrollReset: true });
        }
    }

    // Close the fly out on click outside
    const insideRef = useRef(null);
    const insideLocationButton = useClickInside(insideRef);
    useEffect(() => {
        if(!insideLocationButton) { setIsOpen(false); }
    }, [insideLocationButton]);

    return (
        <LocationButtonWrapper ref={insideRef}>
            <ConditionalWrap condition={(!expanded && !isOpen)} wrap={children => <Tooltip content={label} offsetX={"10px"}>{children}</Tooltip>}>
                <StyledLocationButton onClick={() => setIsOpen(!isOpen)} $isOpen={isOpen}>
                    <IconWrapper>
                        <LocationIcon $expanded={expanded} />
                        {!expanded &&
                            <LocationCode>
                                {code}
                            </LocationCode>
                        }
                    </IconWrapper>

                    {expanded &&
                        <StyledNavItemLabel>
                            {label}
                        </StyledNavItemLabel>
                    }

                </StyledLocationButton>
            </ConditionalWrap>

            {isOpen &&
                <FlyOut>
                    <LocationOption onClick={(e) => handleClick(e, "")} className={!selectedLocation ? "active" : undefined}>
                        Alle locaties
                    </LocationOption>
                    {data?.map(item => (
                        <LocationOption
                            key={item.id}
                            onClick={(e) => handleClick(e, item.id)}
                            className={item?.id === selectedLocation?.id ? "active" : undefined}
                        >
                            {item.name}
                        </LocationOption>
                    ))}
                </FlyOut>
            }
        </LocationButtonWrapper>
    );
}