import Cookies from "js-cookie";

class Api {
    static async get(endpoint, onSuccess = null, onError = null, controller = null) {
        const accessToken = Cookies.get('adhdcentraal-portal_token');

        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/portal/api/v1/${endpoint}`, {
                ...(Boolean(accessToken) && {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }),
                ...(Boolean(controller) && {
                    signal: controller.signal,
                })
            })

            if (!response.ok) throw new Error();
            const data = await response.json();

            onSuccess(data);
        } catch (error) {
            if (error?.name === "AbortError") return;
            if (error?.response?.status === 401) return Cookies.remove('adhdcentraal-portal_token');

            Boolean(onError) && onError(error);
        }
    }

    static async post(endpoint, body, onSuccess = null, onError = null, controller = null, options = {}) {
        const accessToken = Cookies.get('adhdcentraal-portal_token');

        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/portal/api/v1/${endpoint}`, {
                method: "POST",
                body: body,
                ...(Boolean(accessToken) && {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        ...(options?.type !== "formData") && {'Content-Type': 'application/json'},
                    },
                }),
                ...(Boolean(controller) && {
                    signal: controller.signal,
                })
            })

            if (!response.ok) throw new Error();
            const data = await response.json();

            onSuccess(data);
        } catch (error) {
            if (error?.name === "AbortError") return;
            if (error?.response?.status === 401) return Cookies.remove('adhdcentraal-portal_token');

            Boolean(onError) && onError(error);
        }
    }

    static abortRequest(controller) {
        controller.abort();
    }
}

export default Api;