import {Button, buttonVariants} from '../../../components/Button/Button';
import React, {useEffect, useState} from 'react';
import FormModal from '../../../components/Modal/FormModal';
import styled from 'styled-components';
import {Controller, useForm} from 'react-hook-form';
import {FormField, FormFieldWrapper} from '../../../components/FormFields/FormFields';
import {useFetcher, useLoaderData, useParams} from 'react-router-dom';
import {Text} from '../../../components/Text/Text';
import {Statuses} from '../../../constants/enums';
import {getUniqueListBy} from '../../../utils/helpers';
import {PortalSelectField} from '../../../components/PortalFormFields/PortalSelectField/PortalSelectField';

const List = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
`;

const Box = styled.div`
    flex: 1 1 0;
`;

const StyledButton = styled(Button)`
    ${({$variant}) => buttonVariants($variant)};
    border: none;
    width: 100%;
`;

export const AddAppAccount = ({isOpen, handleClose}) => {
    const {patientData} = useLoaderData();
    const [status, setStatus] = useState(Statuses.IDLE);
    const [error, setError] = useState(false);

    const phoneNumbers = [
        {...patientData?.phoneNumberHome && {value: patientData.phoneNumberHome, label: patientData.phoneNumberHome}},
        {...patientData?.phoneNumberMobile && {value: patientData.phoneNumberMobile, label: patientData.phoneNumberMobile}},
        {...patientData?.phoneNumberWork && {value: patientData.phoneNumberWork, label: patientData.phoneNumberWork}},
    ]

    const {control, handleSubmit, formState: { errors }, clearErrors} = useForm({
        defaultValues: {
            intent: "add-app-account",
            email: patientData?.email,
            phoneNumber: ""
        }
    });

    const extendedHandleClose = () => {
        setError(false);
        setStatus(Statuses.IDLE);
        clearErrors();
        handleClose();
    }

    const fetcher = useFetcher();
    const {patientUUID} = useParams();

    const onSubmit = (values) => {
        const data = {
            intent: values?.intent,
            email: values?.email,
            phoneNumber: values?.phoneNumber?.value
        }
        setStatus(Statuses.SUBMITTING);
        setError(false);
        fetcher.submit(data, { method: "POST", action: `/patient/${patientUUID}`});
    }

    // Fetcher callback
    useEffect(() => {
        if(fetcher?.data?.error) {
            setStatus(Statuses.IDLE);
            return setError(true);
        }

        if(fetcher?.data) extendedHandleClose();
        //eslint-disable-next-line
    }, [fetcher]);

    const disabled = status === Statuses.LOADING || status === Statuses.REVALIDATING || status === Statuses.SUBMITTING;

    return (
        <FormModal isOpen={isOpen} handleClose={extendedHandleClose}>
            <FormModal.Header handleClose={extendedHandleClose}>App account toevoegen</FormModal.Header>

            <form id="add-app-account-form" onSubmit={handleSubmit(onSubmit)}>
                <List>
                    <FormField
                        type="TextField"
                        id="EmailAddress"
                        label="E-mailadres"
                        required={true}
                        disabled={true}
                        data={{answer: patientData?.email}}
                    />

                    <FormFieldWrapper
                        id="phoneNumber"
                        label="Telefoonnummer"
                        required={true}
                        error={errors.phoneNumber}
                    >
                        <Controller
                            name="phoneNumber"
                            control={control}
                            rules={{ required: true }}
                            disabled={disabled}
                            render={({field}) => {
                                return (
                                    <PortalSelectField
                                        {...field}
                                        options={getUniqueListBy(phoneNumbers, "value")}
                                        placeholder="Selecteer"
                                    />
                                )
                            }}
                        />
                    </FormFieldWrapper>
                </List>
            </form>

            {error && <Text $error>Er is iets misgegaan, probeer het opnieuw.</Text>}

            <FormModal.Footer>
                <Box><StyledButton type="button" onClick={extendedHandleClose} $variant="secondary">Annuleren</StyledButton></Box>
                <Box><StyledButton type="submit" form="add-app-account-form" disabled={disabled} loading={disabled}>Verstuur uitnodiging</StyledButton></Box>
            </FormModal.Footer>
        </FormModal>
    );
}